@import "../../styles/bootstrap.min.css";

html {
  background: #fff;
  overflow-y: scroll;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -webkit-user-select: none;
  user-select: none;
}

html,
body {
  position: relative;
  height: 100%;
  min-height: 100%;
  background: #fff;
  font-family: "uniicons","PingFang SC" sans-serif !important;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
  zoom: 1;
}

body,
button,
input,
select,
textarea {
  font-size: 0.32rem;
  line-height: 1.5;
  font-family: "uniicons","PingFang SC" sans-serif !important;
}

input,
button,
select,
textarea {
  outline: none;
  resize: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

th {
  text-align: inherit;
}

fieldset,
img {
  border: 0;
}

iframe {
  display: block;
  position: unset !important;
}

abbr,
acronym {
  border: 0;
  font-feature-settings: normal;
  font-variant: normal;
}

del {
  text-decoration: line-through;
}

address,
caption,
cite,
code,
dfn,
em,
th,
var {
  font-style: normal;
}

ol,
ul {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

b,
strong {
  font-weight: normal;
}

a,
img {
  -webkit-touch-callout: none;
}

a,
input,
* {
  -webkit-tap-highlight-color: rgba(255,255,255,0) !important;
  -webkit-focus-ring-color: rgba(255,255,255,0) !important;
  outline: none !important;
}

ins,
a {
  text-decoration: none;
}

input::-ms-clear {
  display: none;
}

input[type=text],
input[type=password],
input[type=submit],
input[type=reset],
input[type=button],
button {
  -webkit-appearance: none;
}

input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

@font-face {
  font-family: uniicons;
  src: url(../../fonts/_uniicons.ttf) format("truetype");
}

.common-paper-pb {
  padding-bottom: 1.653333rem;
}

.common-paper-container-bg {
  width: 100%;
  min-height: 100vh;
  height: auto;
  overflow: hidden;
  background: linear-gradient(337deg, #eccfff00 69.42%, #4ac9ff33 98.13%),linear-gradient(180deg, #d694ffcc 0.22%, #f0ecffcc 64.43%) !important;
}

.common-paper-container-bg.abo {
  background: linear-gradient(6deg, #ffffff00 32.55%, #fd6aeb59 110.95%),linear-gradient(5deg, #ffffff00 33.47%, #ff79f966 93.71%),#fff !important;
}

.common-paper-container-bg.animal {
  background: linear-gradient(6deg, #ffffff00 32.55%, #2a99ff59 110.95%),linear-gradient(5deg, #ffffff00 33.47%, #59d1ff66 93.71%),#fff !important;
}

.common-paper-container-bg.love {
  background: linear-gradient(52deg, #ffffff00 44.14%, #ff81ca59 96.58%),linear-gradient(5deg, #ffffff00 33.47%, #ff4cb766 93.71%),#fff !important;
}

.loading-translate-center .adm-infinite-scroll {
  font-size: 0.426667rem;
}

@media only screen and (min-height:804px)and (device-width: 414px)and (device-height: 896px)and (-webkit-device-pixel-ratio: 2),only screen and (min-height:720px)and (device-width: 375px)and (device-height: 812px)and (-webkit-device-pixel-ratio: 3),only screen and (min-height:804px)and (device-width: 414px)and (device-height: 896px)and (-webkit-device-pixel-ratio: 3) {
  .common-paper-pb {
    padding-bottom: calc(1.653333rem + 0.906667rem) !important;
  }
}

@media only screen and (min-height:804px)and (device-width: 414px)and (device-height: 896px)and (-webkit-device-pixel-ratio: 2),only screen and (min-height:720px)and (device-width: 375px)and (device-height: 812px)and (-webkit-device-pixel-ratio: 3),only screen and (min-height:804px)and (device-width: 414px)and (device-height: 896px)and (-webkit-device-pixel-ratio: 3) {
  .tabbar-pb {
    padding-bottom: 0.906667rem !important;
  }
}

.light {
  font-weight: 300;
}

.bold-400 {
  font-weight: 400;
}

.bold-500 {
  font-weight: 500;
}

.bold {
  font-weight: 600;
}

.normal {
  font-size: 0.48rem !important;
}

.big {
  font-size: 0.533333rem !important;
}

.bigger {
  font-size: 0.64rem !important;
}

.biggest {
  font-size: 0.746667rem !important;
}

.biggest-more {
  font-size: 1.066667rem !important;
}

.small {
  font-size: 0.426667rem !important;
}

.smaller {
  font-size: 0.373333rem !important;
}

.smallest {
  font-size: 0.32rem !important;
}

.singleText {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.domBigRadius {
  border-radius: 0.533333rem !important;
}

.domRadius {
  border-radius: 0.32rem !important;
}

.domHalfRadius {
  border-radius: 0.16rem !important;
}

.placeholder-dom-30 {
  display: inline-block;
  min-height: 0.8rem;
}

.placeholder-dom-60 {
  display: inline-block;
  min-height: 1.6rem;
}

.placeholder-dom-220 {
  display: inline-block;
  min-height: 1.6rem;
}

.inputFont {
  color: #6317ff !important;
}

.greyFont {
  color: #9d9aa6 !important;
}

.greyLightFont {
  color: #45424f !important;
}

.greyerFont {
  color: #f0edf8 !important;
}

.greyestFont {
  color: #626262 !important;
}

.whiteFont {
  color: #fff !important;
}

.darkFont {
  color: #916c7f !important;
}

.strongFont {
  color: #28262f !important;
}

.subStrongFont {
  color: #45424f !important;
}

.transparentFont {
  color: transparent !important;
}

.coolerBlueFont {
  color: #5585ff !important;
}

.lightBlueFont {
  color: #2fa0b0 !important;
}

.greenFont {
  color: #75c639 !important;
}

.redFont {
  color: #df392e !important;
}

.redMagentaFont {
  color: #f041aa !important;
}

.lightPurpleFont {
  color: #be8dba !important;
}

.warmerRedFont {
  color: #ff5b74 !important;
}

.yellowFont {
  color: #8b7051 !important;
}

.mainFont {
  color: #764cfc !important;
}

.aboMainFont {
  color: #bd3ea1 !important;
}

.aboSubTitleFont {
  color: #513d4d !important;
}

.animalMainFont {
  color: #0081ff !important;
}

.animalSubMainFont {
  color: #4b5160 !important;
}

.loveMainFont {
  color: #ff85aa !important;
}

.loveSubTitleFont {
  color: #634959 !important;
}

Bright violet,
.filter {
  filter: blur(0.154667rem);
}

.whiteBg {
  background: #fff !important;
}

.redBg {
  background: linear-gradient(270deg, #df392e -2.5%, #df392e 101.6%);
}

.aboBtnBg {
  background: linear-gradient(93deg, #fc69ea 2.81%, #af4ffa 51.4%, #44b0ff 100%);
}

.aniamalBtnBg {
  background: linear-gradient(90deg, #1d8df4 3.03%, #2f5cf9 99.03%);
}

.paperPersonalityBg {
  background: linear-gradient(90deg, #a362f7 0%, #b36df9 100%);
}

.greyerBg {
  background: #f0edf8;
}

.mainBg {
  background: #8f6cfc;
}

.noborder {
  border: none !important;
}

.mainFontFamily {
  font-family: uniicons;
}

.flex-row {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.grow-1 {
  flex-grow: 1;
}

.flex-1 {
  flex: 1 1;
}

.flex-2 {
  flex: 2 1;
}

.flex-3 {
  flex: 3 1;
}

.align {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.no-wrap {
  flex-wrap: nowrap;
  white-space: nowrap;
}

.auto-wrap {
  flex-wrap: wrap;
  white-space: pre-wrap;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-center {
  justify-content: center;
}

.icon.vip {
  background-image: url(../../assets/vip.png);
}

svg.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
  font-size: 0.426667rem;
}

svg.icon svg:not(:root) {
  overflow: hidden;
}

.no-data {
  margin-top: 10vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.no-data img {
  width: 80vw;
}

.w-95 {
  width: 95% !important;
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.dom-mt {
  margin-top: 0.533333rem;
}

.dom-mt-10 {
  margin-right: 0.266667rem;
}

.dom-mb {
  margin-bottom: 0.533333rem;
}

.dom-ml {
  margin-left: 0.533333rem;
}

.dom-mr {
  margin-right: 0.533333rem;
}

.dom-m-all {
  margin: 0.533333rem;
}

.dom-pt {
  padding-top: 0.8rem;
}

.dom-pb {
  padding-bottom: 0.8rem;
}

.dom-pl {
  padding-left: 0.8rem;
}

.dom-pr {
  padding-right: 0.8rem;
}

.dom-p-all {
  padding: 0.8rem;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.p-05 {
  padding: 0.053333rem !important;
}

.p-1 {
  padding: 0.106667rem !important;
}

.p-15 {
  padding: 0.16rem !important;
}

.p-2 {
  padding: 0.213333rem !important;
}

.p-25 {
  padding: 0.32rem !important;
}

.p-3 {
  padding: 0.426667rem !important;
}

.p-35 {
  padding: 0.533333rem !important;
}

.p-45 {
  padding: 0.8rem !important;
}

.px-05 {
  padding-left: 0.053333rem !important;
  padding-right: 0.053333rem !important;
}

.px-1 {
  padding-left: 0.106667rem !important;
  padding-right: 0.106667rem !important;
}

.px-15 {
  padding-left: 0.16rem !important;
  padding-right: 0.16rem !important;
}

.px-2 {
  padding-left: 0.213333rem !important;
  padding-right: 0.213333rem !important;
}

.px-25 {
  padding-left: 0.32rem !important;
  padding-right: 0.32rem !important;
}

.px-3 {
  padding-left: 0.426667rem !important;
  padding-right: 0.426667rem !important;
}

.px-35 {
  padding-left: 0.533333rem !important;
  padding-right: 0.533333rem !important;
}

.px-4 {
  padding-left: 0.666667rem !important;
  padding-right: 0.666667rem !important;
}

.px-45 {
  padding-left: 0.8rem !important;
  padding-right: 0.8rem !important;
}

.py-05 {
  padding-top: 0.053333rem !important;
  padding-bottom: 0.053333rem !important;
}

.py-1 {
  padding-top: 0.106667rem !important;
  padding-bottom: 0.106667rem !important;
}

.py-15 {
  padding-top: 0.16rem !important;
  padding-bottom: 0.16rem !important;
}

.py-2 {
  padding-top: 0.213333rem !important;
  padding-bottom: 0.213333rem !important;
}

.py-25 {
  padding-top: 0.32rem !important;
  padding-bottom: 0.32rem !important;
}

.py-3 {
  padding-top: 0.426667rem !important;
  padding-bottom: 0.426667rem !important;
}

.py-35 {
  padding-top: 0.533333rem !important;
  padding-bottom: 0.533333rem !important;
}

.py-45 {
  padding-top: 0.8rem !important;
  padding-bottom: 0.8rem !important;
}

.pt-05 {
  padding-top: 0.053333rem !important;
}

.pt-1 {
  padding-top: 0.106667rem !important;
}

.pt-15 {
  padding-top: 0.16rem !important;
}

.pt-2 {
  padding-top: 0.213333rem !important;
}

.pt-25 {
  padding-top: 0.32rem !important;
}

.pt-3 {
  padding-top: 0.426667rem !important;
}

.pt-35 {
  padding-top: 0.533333rem !important;
}

.pt-45 {
  padding-top: 0.8rem !important;
}

.pb-05 {
  padding-bottom: 0.053333rem !important;
}

.pb-1 {
  padding-bottom: 0.106667rem !important;
}

.pb-15 {
  padding-bottom: 0.16rem !important;
}

.pb-2 {
  padding-bottom: 0.213333rem !important;
}

.pb-25 {
  padding-bottom: 0.32rem !important;
}

.pb-3 {
  padding-bottom: 0.426667rem !important;
}

.pb-35 {
  padding-bottom: 0.533333rem !important;
}

.pb-45 {
  padding-bottom: 0.8rem !important;
}

.pl-05 {
  padding-left: 0.053333rem !important;
}

.pl-1 {
  padding-left: 0.106667rem !important;
}

.pl-15 {
  padding-left: 0.16rem !important;
}

.pl-2 {
  padding-left: 0.213333rem !important;
}

.pl-25 {
  padding-left: 0.32rem !important;
}

.pl-3 {
  padding-left: 0.426667rem !important;
}

.pl-35 {
  padding-left: 0.533333rem !important;
}

.pl-4 {
  padding-left: 0.666667rem !important;
}

.pl-45 {
  padding-left: 0.8rem !important;
}

.pr-05 {
  padding-right: 0.053333rem !important;
}

.pr-1 {
  padding-right: 0.106667rem !important;
}

.pr-15 {
  padding-right: 0.16rem !important;
}

.pr-2 {
  padding-right: 0.213333rem !important;
}

.pr-25 {
  padding-right: 0.32rem !important;
}

.pr-3 {
  padding-right: 0.426667rem !important;
}

.pr-35 {
  padding-right: 0.533333rem !important;
}

.pr-4 {
  padding-right: 0.666667rem !important;
}

.pr-45 {
  padding-right: 0.8rem !important;
}

.m-05 {
  margin: 0.053333rem !important;
}

.m-1 {
  margin: 0.106667rem !important;
}

.m-15 {
  margin: 0.16rem !important;
}

.m-2 {
  margin: 0.213333rem !important;
}

.m-25 {
  margin: 0.32rem !important;
}

.m-3 {
  margin: 0.426667rem !important;
}

.m-35 {
  margin: 0.533333rem !important;
}

.m-4 {
  margin: 0.666667rem !important;
}

.m-45 {
  margin: 0.8rem !important;
}

.mx-05 {
  margin-left: 0.053333rem !important;
  margin-right: 0.053333rem !important;
}

.mx-1 {
  margin-left: 0.106667rem !important;
  margin-right: 0.106667rem !important;
}

.mx-15 {
  margin-left: 0.16rem !important;
  margin-right: 0.16rem !important;
}

.mx-2 {
  margin-left: 0.213333rem !important;
  margin-right: 0.213333rem !important;
}

.mx-25 {
  margin-left: 0.32rem !important;
  margin-right: 0.32rem !important;
}

.mx-3 {
  margin-left: 0.426667rem !important;
  margin-right: 0.426667rem !important;
}

.mx-35 {
  margin-left: 0.533333rem !important;
  margin-right: 0.533333rem !important;
}

.mx-4 {
  margin-left: 0.666667rem !important;
  margin-right: 0.666667rem !important;
}

.mx-45 {
  margin-left: 0.8rem !important;
  margin-right: 0.8rem !important;
}

.my-05 {
  margin-top: 0.053333rem !important;
  margin-bottom: 0.053333rem !important;
}

.my-1 {
  margin-top: 0.106667rem !important;
  margin-bottom: 0.106667rem !important;
}

.my-15 {
  margin-top: 0.16rem !important;
  margin-bottom: 0.16rem !important;
}

.my-2 {
  margin-top: 0.213333rem !important;
  margin-bottom: 0.213333rem !important;
}

.my-25 {
  margin-top: 0.32rem !important;
  margin-bottom: 0.32rem !important;
}

.my-3 {
  margin-top: 0.426667rem !important;
  margin-bottom: 0.426667rem !important;
}

.my-35 {
  margin-top: 0.533333rem !important;
  margin-bottom: 0.533333rem !important;
}

.my-4 {
  margin-top: 0.666667rem !important;
  margin-bottom: 0.666667rem !important;
}

.my-45 {
  margin-top: 0.8rem !important;
  margin-bottom: 0.8rem !important;
}

.mt-05 {
  margin-top: 0.053333rem !important;
}

.mt-1 {
  margin-top: 0.106667rem !important;
}

.mt-15 {
  margin-top: 0.16rem !important;
}

.mt-2 {
  margin-top: 0.213333rem !important;
}

.mt-25 {
  margin-top: 0.32rem !important;
}

.mt-3 {
  margin-top: 0.426667rem !important;
}

.mt-35 {
  margin-top: 0.533333rem !important;
}

.mt-4 {
  margin-top: 0.666667rem !important;
}

.mt-45 {
  margin-top: 0.8rem !important;
}

.mb-05 {
  margin-bottom: 0.053333rem !important;
}

.mb-1 {
  margin-bottom: 0.106667rem !important;
}

.mb-15 {
  margin-bottom: 0.16rem !important;
}

.mb-2 {
  margin-bottom: 0.213333rem !important;
}

.mb-25 {
  margin-bottom: 0.32rem !important;
}

.mb-3 {
  margin-bottom: 0.426667rem !important;
}

.mb-35 {
  margin-bottom: 0.533333rem !important;
}

.mb-4 {
  margin-bottom: 0.666667rem !important;
}

.mb-45 {
  margin-bottom: 0.8rem !important;
}

.ml-05 {
  margin-left: 0.053333rem !important;
}

.ml-1 {
  margin-left: 0.106667rem !important;
}

.ml-15 {
  margin-left: 0.16rem !important;
}

.ml-2 {
  margin-left: 0.213333rem !important;
}

.ml-25 {
  margin-left: 0.32rem !important;
}

.ml-3 {
  margin-left: 0.426667rem !important;
}

.ml-35 {
  margin-left: 0.533333rem !important;
}

.ml-4 {
  margin-left: 0.666667rem !important;
}

.ml-45 {
  margin-left: 0.8rem !important;
}

.mr-05 {
  margin-right: 0.053333rem !important;
}

.mr-1 {
  margin-right: 0.106667rem !important;
}

.mr-15 {
  margin-right: 0.16rem !important;
}

.mr-2 {
  margin-right: 0.213333rem !important;
}

.mr-25 {
  margin-right: 0.32rem !important;
}

.mr-3 {
  margin-right: 0.426667rem !important;
}

.mr-35 {
  margin-right: 0.533333rem !important;
}

.mr-4 {
  margin-right: 0.666667rem !important;
}

.mr-45 {
  margin-right: 0.8rem !important;
}

.max-width {
  max-width: 10rem !important;
}

.fixed-x-center {
  transform: translateX(-50%) !important;
  left: 50% !important;
}

.icon {
  display: inline-block;
  width: 0.48rem;
  height: 0.48rem;
  background-size: contain;
  background-repeat: no-repeat;
}

.paper.logo {
  background-image: url(../../assets/paper/logo.png);
}

.paper.report {
  background-image: url(../../assets/paper/icon-report.png);
}

.paper.reportdisabled,
.abo.reportdisabled,
.love.reportdisabled,
.animal.reportdisabled {
  background-image: url(../../assets/paper/icon-report-disabled.png);
}

.abo.report {
  background-image: url(../../assets/abo/icon-report.png);
}

.love.report {
  background-image: url(../../assets/love/icon-report.png);
}

.animal.report {
  background-image: url(../../assets/animal/icon-report.png);
}

.love.BtnBg {
  background-image: url(../../assets/love/love-btn.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.paper.boradcast {
  background-image: url(../../assets/paper/icon-boradcast.png);
}

.paper.previous {
  background-image: url(../../assets/paper/icon-previous.png);
}

.paper.next {
  background-image: url(../../assets/paper/icon-next.png);
}

.report.close {
  background-image: url(../../assets/report/icon-close.png);
}

.paper.reportTextBg {
  background-image: url(../../assets/paper/top-peronality-bg.png);
}

.report.resultType {
  background-image: url(../../assets/report/icon-result.png);
}

.paper.paperFull {
  background-image: url(../../assets/paper/top-paper-full.png);
}

.paper.paperQuick {
  background-image: url(../../assets/paper/top-paper-quick.png);
}

.paper.paperStandard {
  background-image: url(../../assets/paper/top-paper-standard.png);
}

.paper.paperShare {
  background-image: url(../../assets/paper/top-paper-share.png);
}

.paper.abo.share {
  background-image: url(../../assets/abo/share.png);
}

.paper.animal.share {
  background-image: url(../../assets/animal/share.png);
}

.paper.love.share {
  background-image: url(../../assets/love/share.png);
}

.paper.paperTest {
  background-image: url(../../assets/paper/top-paper-test.png);
}

.paper.circle {
  background-image: url(../../assets/paper/circle.png);
}

.paper.wait {
  width: 0.48rem;
  height: 0.48rem;
  min-width: 0.48rem;
  max-height: 0.48rem;
  border: 0.053333rem solid #e2e2e2;
  background-color: #fff;
  border-radius: 50%;
  box-sizing: border-box;
  position: relative;
}

.paper.wait::after {
  content: "";
  width: 0.213333rem;
  height: 0.213333rem;
  background-color: #e2e2e2;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.mbti.groupCompleted {
  background-image: url(../../assets/paper/icon-complete.png);
}

.mbti.groupProgress {
  background-image: url(../../assets/paper/icon-progress.png);
}

.love.groupCompleted {
  background-image: url(../../assets/paper/icon-love-complete.png);
}

.love.groupProgress {
  background-image: url(../../assets/paper/icon-love-progress.png);
}

.report.titleBg {
  background-image: url(../../assets/report/title-bg.png);
}

.report.mbti.row-1-1 {
  background-image: url(../../assets/report/row-1-1.png);
}

.report.mbti.row-1-2 {
  background-image: url(../../assets/report/row-1-2.png);
}

.report.mbti.row-2-1 {
  background-image: url(../../assets/report/row-2-1.png);
}

.report.mbti.row-2-2 {
  background-image: url(../../assets/report/row-2-2.png);
}

.report.mbti.row-3-1 {
  background-image: url(../../assets/report/row-3-1.png);
}

.report.mbti.row-3-2 {
  background-image: url(../../assets/report/row-3-2.png);
}

.report.mbti.row-4-1 {
  background-image: url(../../assets/report/row-4-1.png);
}

.report.mbti.row-4-2 {
  background-image: url(../../assets/report/row-4-2.png);
}

.report.mbti.row-5-1 {
  background-image: url(../../assets/report/row-5-1.png);
}

.report.mbti.row-5-2 {
  background-image: url(../../assets/report/row-5-2.png);
}

.report.mbti.row-6-1 {
  background-image: url(../../assets/report/row-6-1.png);
}

.report.mbti.row-6-2 {
  background-image: url(../../assets/report/row-6-2.png);
}

.report.abo.row-1-1 {
  background-image: url(../../assets/report/abo/row-1-1.png);
}

.report.abo.row-1-2 {
  background-image: url(../../assets/report/abo/row-1-2.png);
}

.report.abo.row-2-1 {
  background-image: url(../../assets/report/abo/row-2-1.png);
}

.report.abo.row-2-2 {
  background-image: url(../../assets/report/abo/row-2-2.png);
}

.report.abo.row-3-1 {
  background-image: url(../../assets/report/abo/row-3-1.png);
}

.report.abo.row-3-2 {
  background-image: url(../../assets/report/abo/row-3-2.png);
}

.report.abo.row-4-1 {
  background-image: url(../../assets/report/abo/row-4-1.png);
}

.report.abo.row-4-2 {
  background-image: url(../../assets/report/abo/row-4-2.png);
}

.report.abo.row-5-1 {
  background-image: url(../../assets/report/abo/row-5-1.png);
}

.report.abo.row-5-2 {
  background-image: url(../../assets/report/abo/row-5-2.png);
}

.report.abo.row-6-1 {
  background-image: url(../../assets/report/abo/row-6-1.png);
}

.report.abo.row-6-2 {
  background-image: url(../../assets/report/abo/row-6-2.png);
}

.report.animal.row-1-1 {
  background-image: url(../../assets/report/animal/row-1-1.png);
}

.report.animal.row-1-2 {
  background-image: url(../../assets/report/animal/row-1-2.png);
}

.report.animal.row-2-1 {
  background-image: url(../../assets/report/animal/row-2-1.png);
}

.report.animal.row-2-2 {
  background-image: url(../../assets/report/animal/row-2-2.png);
}

.report.animal.row-3-1 {
  background-image: url(../../assets/report/animal/row-3-1.png);
}

.report.animal.row-3-2 {
  background-image: url(../../assets/report/animal/row-3-2.png);
}

.report.animal.row-4-1 {
  background-image: url(../../assets/report/animal/row-4-1.png);
}

.report.animal.row-4-2 {
  background-image: url(../../assets/report/animal/row-4-2.png);
}

.report.animal.row-5-1 {
  background-image: url(../../assets/report/animal/row-5-1.png);
}

.report.animal.row-5-2 {
  background-image: url(../../assets/report/animal/row-5-2.png);
}

.report.animal.row-6-1 {
  background-image: url(../../assets/report/animal/row-6-1.png);
}

.report.animal.row-6-2 {
  background-image: url(../../assets/report/animal/row-6-2.png);
}

.report.love.row-1-1 {
  background-image: url(../../assets/report/love/row-1-1.png);
}

.report.love.row-1-2 {
  background-image: url(../../assets/report/love/row-1-2.png);
}

.report.love.row-2-1 {
  background-image: url(../../assets/report/love/row-2-1.png);
}

.report.love.row-2-2 {
  background-image: url(../../assets/report/love/row-2-2.png);
}

.report.love.row-3-1 {
  background-image: url(../../assets/report/love/row-3-1.png);
}

.report.love.row-3-2 {
  background-image: url(../../assets/report/love/row-3-2.png);
}

.report.love.row-4-1 {
  background-image: url(../../assets/report/love/row-4-1.png);
}

.report.love.row-4-2 {
  background-image: url(../../assets/report/love/row-4-2.png);
}

.report.love.row-5-1 {
  background-image: url(../../assets/report/love/row-5-1.png);
}

.report.love.row-5-2 {
  background-image: url(../../assets/report/love/row-5-2.png);
}

.report.love.row-6-1 {
  background-image: url(../../assets/report/love/row-6-1.png);
}

.report.love.row-6-2 {
  background-image: url(../../assets/report/love/row-6-2.png);
}

.report.sum {
  background-image: url(../../assets/report/icon-base-sum.png);
}

.report.women {
  background-image: url(../../assets/report/icon-base-women.png);
}

.report.men {
  background-image: url(../../assets/report/icon-base-men.png);
}

.common-dialog-box.adm-center-popup {
  --border-radius: 0.32rem !important;
}

.common-dialog-box.adm-center-popup .adm-center-popup-body {
  background: linear-gradient(52deg, #ffffff00 44.14%, #a851ff59 96.58%),linear-gradient(344deg, #ffffff00 36.22%, #ff92d366 102.46%),#fff;
}

.common-dialog-box.adm-center-popup .adm-center-popup-body .adm-dialog-title {
  margin: 0.533333rem 0.32rem 0.533333rem;
}

.common-dialog-box.adm-center-popup .adm-center-popup-body .adm-dialog-content {
  padding: 0 0.32rem 0.8rem;
}

.common-dialog-box.adm-center-popup .adm-center-popup-body .adm-auto-center-content {
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}

.common-dialog-box.adm-center-popup .adm-center-popup-body .adm-dialog-footer .adm-dialog-action-row {
  border-top: none;
}

.common-dialog-box.adm-center-popup .adm-center-popup-body .adm-dialog-footer .adm-dialog-action-row button {
  border-radius: 0.213333rem;
  font-size: 0.373333rem;
  height: 0.96rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.common-dialog-box.adm-center-popup .adm-center-popup-body .adm-dialog-footer .adm-dialog-action-row button:nth-child(1) {
  color: #b1abc4;
  background: #f3eef6;
  margin-left: 0.533333rem;
  margin-right: 0.2rem;
  margin-bottom: 0.533333rem;
}

.common-dialog-box.adm-center-popup .adm-center-popup-body .adm-dialog-footer .adm-dialog-action-row button:nth-child(2) {
  background-color: #764cfc;
  color: #fff;
  font-size: 0.373333rem !important;
  font-style: normal;
  font-weight: 500;
  margin-left: 0.2rem;
  margin-right: 0.533333rem;
  margin-bottom: 0.533333rem;
}

.common-dialog-box.animal button:nth-child(2) {
  background: #2a99ff !important;
}

.common-dialog-box.animal .adm-center-popup-body {
  background: linear-gradient(6deg, #ffffff00 32.55%, #2a99ff59 110.95%),linear-gradient(5deg, #ffffff00 33.47%, #59d1ff66 93.71%),#fff !important;
}

.common-dialog-box.love button:nth-child(2) {
  background: #f662a0 !important;
}

.common-dialog-box.love .adm-center-popup-body {
  background: linear-gradient(52deg, #ffffff00 44.14%, #ff81ca59 96.58%),linear-gradient(5deg, #ffffff00 33.47%, #ff4cb766 93.71%),#fff !important;
}

.common-dialog-box.abo button:nth-child(2) {
  background: linear-gradient(90deg, #fd6aeb -2.14%, #fd6aeb 100%) !important;
}

.common-dialog-box.abo .adm-center-popup-body {
  background: linear-gradient(6deg, #ffffff00 32.55%, #fd6aeb59 110.95%),linear-gradient(5deg, #ffffff00 33.47%, #ff79f966 93.71%),#fff !important;
}

.fs-20 {
  font-size: 0.533333rem;
}

.fs-18 {
  font-size: 0.48rem;
}

.fs-16 {
  font-size: 0.426667rem;
}

.fw-900 {
  font-weight: 900;
}

.mb-10 {
  margin-bottom: 0.266667rem;
}

.ml-5 {
  margin-left: 0.133333rem;
}

.mr-5 {
  margin-right: 0.133333rem;
}

.mb-5px {
  margin-bottom: 0.133333rem;
}

.important-count {
  color: #e51d1f;
}

.unpay-modal-body {
  background-color: #fff;
  text-align: center;
  width: 7.866667rem;
  padding-top: 0.8rem !important;
  background-image: url(../../assets/report/pay-dialog-bg.png);
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 0.32rem;
}

.unpay-modal-body .adm-dialog-header {
  margin-bottom: 0;
}

.unpay-modal-body .unpay-modal-body-icon {
  font-size: 1.28rem;
  color: #df8b28;
}

.unpay-modal-body .unpay-modal-body-content .unpay-modal-body-warn {
  font-weight: bold;
  font-size: 0.48rem;
  margin: 0.533333rem 0 0.8rem 0;
}

.unpay-modal-body .unpay-modal-body-content .unpay-modal-body-btns {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 0.533333rem;
  gap: 0.533333rem;
}

.unpay-modal-body .unpay-modal-body-content .unpay-modal-body-btns .unpay-modal-body-btn {
  width: 6.56rem;
  height: 1.226667rem;
  line-height: 1.226667rem;
  border-radius: 0.613333rem;
  font-size: 0.426667rem;
  font-weight: 400;
  font-family: PingFang SC;
}

.unpay-modal-body .unpay-modal-body-content .unpay-modal-body-btns .unpay-modal-body-btn:first-child {
  background: linear-gradient(93deg, #ffca40 1.15%, #ffb21c 63.81%);
  font-weight: 900;
  color: #522f06;
}

.unpay-modal-body .unpay-modal-body-content .unpay-modal-body-btns .unpay-modal-body-btn:last-child {
  border: 0.026667rem solid #df8b28;
  color: #522f06;
  margin-bottom: 0.72rem;
}

.unpay-modal-body .unpay-modal-body-content .unpay-modal-body-close {
  color: #fff;
  position: absolute;
  bottom: -1.066667rem;
  left: 50%;
  transform: translateX(-50%);
  width: 0.64rem;
  height: 0.64rem;
  background-size: 100%;
  background-position: center;
  background-image: url(../../assets/report/close-modal.png);
}

.tabbar-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  background-color: #fff;
  z-index: 99;
  border-top: 0.026667rem solid #dedede;
}

.tabbar-footer .adm-tab-bar-item {
  color: #28262f;
}

.tabbar-footer .adm-tab-bar-item .adm-tab-bar-item-icon {
  width: 0.533333rem;
  height: 0.586667rem;
}

.tabbar-footer .adm-tab-bar-item-active {
  color: #764cfc;
  font-weight: bold;
}